import React, { useCallback, useMemo, type ReactNode, type SyntheticEvent } from 'react';
import type { ButtonProps } from '@atlaskit/button';
import type { TriggerProps } from '@atlaskit/popup/types';
import { InvitePeopleModalContextProvider } from '@atlassian/invite-people/InvitePeopleModalContext';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { editionAwarenessDropdownEntryPoint } from '@atlassian/jira-edition-awareness-dropdown/entrypoint.tsx';
import {
	FEATURE_ASSETS,
	FEATURE_CHANGE_MANAGEMENT,
	FEATURE_INCIDENT_MANAGEMENT,
	FEATURE_INVITE_PEOPLE,
	FEATURE_VIRTUAL_SERVICE_AGENT,
	type ApplicationProps,
	type SupportedApplicationEdition,
} from '@atlassian/jira-edition-awareness-dropdown/src/common/types.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { useBoolean } from '@atlassian/jira-platform-use-boolean/src/useBoolean.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	ContextualAnalyticsData,
	DROPDOWN,
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils/src/controllers/use-fetch-onboarding-reverse-trial/index.tsx';
import { useForceOpenPremiumPopupOnDay3 } from '@atlassian/jira-reverse-trial/src/controllers/use-force-open-premium-popup-on-day-3/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { EACommonAnalyticsAttributes } from '../../types.tsx';
import {
	PREMIUM_FEATURES_COHORT_VARIANT,
	firePremiumFeaturesExperimentExposure,
} from './utils.tsx';
/**
 * This was a capability developed during reverse trials where we automatically open the dialog after
 * 3 days of being on the trial. This happens for all customers that have a premium trial on sign up.
 */
const useAutoOpenPopover = (
	openDialog: () => void,
	analyticsAttributes?: EACommonAnalyticsAttributes,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onPopupToggle = useCallback(() => {
		// this is just used when we are opening the dialog 3 days after the customer
		// has started their trial, to force it open without the customers interaction
		openDialog();

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'popup' }),
			'editionAwarenessTrialPillPopup',
			{
				...analyticsAttributes,
				flagKey: 'demonstrating_the_value_of_jira_premium_features',
				autoOpened: true,
				premiumFeaturesCohort: PREMIUM_FEATURES_COHORT_VARIANT,
			},
		);
	}, [openDialog, createAnalyticsEvent, analyticsAttributes]);

	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	useForceOpenPremiumPopupOnDay3({
		shouldRun: Boolean(isReady && premiumTrialOnSignup),
		onPopupToggle,
	});
};

export type DropdownTriggerProps = {
	onClick: ButtonProps['onClick'];
	triggerProps: TriggerProps;
	ref: React.Ref<HTMLButtonElement>;
};

type DropdownProps = {
	productKey: CanonicalId;
	applicationEdition: SupportedApplicationEdition;
	children: (popupProps: DropdownTriggerProps) => ReactNode;
	analyticsAttributes?: EACommonAnalyticsAttributes;
	// this is a temporary prop to pass the trial end time to the dropdown
	// we shuold decide whether we want to keep this or not by confirming the source of the truth
	trialEndTime?: number;
};

export const Dropdown = ({
	productKey,
	applicationEdition,
	children,
	analyticsAttributes,
	trialEndTime,
}: DropdownProps) => {
	const cloudId = useCloudId();
	// While rendering this component, the project loading is already done
	// so we can skip the check for loading state
	const { data: project } = useProject();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [dialogOpen, { on: openDialog, toggle: toggleDialog, off: closeDialog }] =
		useBoolean(false);
	const { getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	const isJSMPremiumPillExperiment =
		getIsEligibleForJsmPremiumPillExperiment() &&
		expValEquals('jsm_premium_trial_pill_experiment', 'cohort', 'variation');

	const handleTogglePopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			const updatedEvent = analyticsEvent.update((payload) => ({
				...payload,
				action: 'toggled',
				actionSubject: 'popup',
			}));

			const show = !dialogOpen;

			fireUIAnalytics(updatedEvent, 'editionAwarenessTrialPillPopup', {
				...analyticsAttributes,
				show,
				flagKey: 'demonstrating_the_value_of_jira_premium_features',
				premiumFeaturesCohort: PREMIUM_FEATURES_COHORT_VARIANT,
			});

			toggleDialog();

			firePremiumFeaturesExperimentExposure({
				productKey,
				edition: applicationEdition,
				inGracePeriod: false,
				createAnalyticsEvent,
			});
		},
		[
			dialogOpen,
			analyticsAttributes,
			toggleDialog,
			createAnalyticsEvent,
			productKey,
			applicationEdition,
		],
	);

	const handleTogglePopupJSMPremiumPillExperiment = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			const updatedEvent = analyticsEvent.update((payload) => ({
				...payload,
				action: 'toggled',
				actionSubject: 'popup',
			}));

			const show = !dialogOpen;

			fireUIAnalytics(updatedEvent, 'editionAwarenessTrialPillPopup', {
				...analyticsAttributes,
				show,
				flagKey: 'jsm_premium_trial_pill_experiment',
			});

			toggleDialog();
		},
		[dialogOpen, analyticsAttributes, toggleDialog],
	);

	const entryPointParams = useMemo(
		() => ({ cloudId, productKey, projectKey: project?.key }),
		[cloudId, productKey, project?.key],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		editionAwarenessDropdownEntryPoint,
		entryPointParams,
	);

	const loadEntryPoint = entryPointActions.load;
	const onAutoOpen = useCallback(() => {
		loadEntryPoint();
		openDialog();

		firePremiumFeaturesExperimentExposure({
			productKey,
			edition: applicationEdition,
			inGracePeriod: false,
			createAnalyticsEvent,
		});
	}, [applicationEdition, createAnalyticsEvent, loadEntryPoint, openDialog, productKey]);
	useAutoOpenPopover(onAutoOpen, analyticsAttributes);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true);

	const onFeatureClick: ApplicationProps['onFeatureClick'] = useCallback(
		(e, analyticsEvent, featureId) => {
			// We only want to close the dropdown if the user clicked on the invite people feature
			if (featureId === FEATURE_INVITE_PEOPLE) {
				handleTogglePopup(e, analyticsEvent);
			}
			// close the dropdown if the user clicks on any of the JSM premium features
			if (
				[
					FEATURE_CHANGE_MANAGEMENT,
					FEATURE_INCIDENT_MANAGEMENT,
					FEATURE_ASSETS,
					FEATURE_VIRTUAL_SERVICE_AGENT,
				].includes(featureId)
			) {
				handleTogglePopupJSMPremiumPillExperiment(e, analyticsEvent);
			}
		},
		[handleTogglePopup, handleTogglePopupJSMPremiumPillExperiment],
	);

	const runtimeProps = useMemo(
		() => ({
			productKey,
			applicationEdition,
			onFeatureClick,
			trialEndTime,
			onMaybeLaterClick: closeDialog,
		}),
		[productKey, applicationEdition, onFeatureClick, trialEndTime, closeDialog],
	);

	return (
		<ContextualAnalyticsData sourceName="editionAwareness" sourceType={DROPDOWN}>
			<InvitePeopleModalContextProvider>
				<Popup
					messageId="navigation-apps-sidebar-edition-awareness.ui.trial-pill.trial-pill-button.dropdown.jira-popup"
					messageType="transactional"
					isOpen={dialogOpen}
					onClose={closeDialog}
					placement="bottom-end"
					content={() => (
						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointReferenceSubject}
							id="editionAwarenessDropdown"
							packageName="@atlassian/jira-navigation-apps-sidebar-edition-awareness"
							teamName="growth-tako"
							runtimeProps={runtimeProps}
							fallback={null}
						/>
					)}
					trigger={(triggerProps) =>
						children({
							triggerProps,
							onClick: isJSMPremiumPillExperiment
								? handleTogglePopupJSMPremiumPillExperiment
								: handleTogglePopup,
							ref: mergeRefs(triggerProps.ref, triggerRef),
						})
					}
				/>
			</InvitePeopleModalContextProvider>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
