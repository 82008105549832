import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products/src/index.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

/**
 * Checks whether a user meets the requisites for the Edition Awareness component
 * to be shown in the top navigation. The user must have only JSW on their instance.
 */
export function useSiteHasOnlyJira() {
	const tenantContext = useTenantContext();

	try {
		const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
		const firstExplicitlyLicensedProduct = explicitlyLicensedProducts[0];
		return explicitlyLicensedProducts.length === 1 && firstExplicitlyLicensedProduct === SOFTWARE;
	} catch {
		return false;
	}
}

/**
 * Convert undefined to string
 * to shown in error analytics monitoring
 */
export function handleUndefinedInObjectForAnalytics(
	input: AnalyticsAttributes,
): AnalyticsAttributes {
	if (typeof input === 'object') {
		return JSON.parse(
			JSON.stringify(input, (_, value) => (typeof value === 'undefined' ? 'undefined' : value)),
		);
	}
	return input;
}
